//import React from 'react'
import { useMediaQuery } from 'react-responsive'

export const Mobile = () => {

    // MTD tar vi tablets under paraplyen mobile
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' })

    return isMobile;
};


export const SelectProperty = (array, filter) => {

    const propName = filter;

    const OnlyProperty = array.map(item => item[propName]);
    const OnlyPropertySorted = OnlyProperty.sort();
    const uniqueProperty = OnlyPropertySorted.reduce((b,c) => (
        (b[b.findIndex(d => d[propName]===c)] ||
        // eslint-disable-next-line 
        b[b.push({[propName]: c, count: 0})-1]).count++,b), 
        []
    );
    const uniquePropertySorted = uniqueProperty.sort(function(a, b){
    return b.count-a.count
    })
    const PropertyOptions = uniquePropertySorted.map(value => { 
        return {value: value[propName], label: value[propName] + ' (' + value.count + ')'}; 
    //}
    });
    
    return PropertyOptions;
};

export const SearchLists = (array, field, searchText) => {

    let searchArray = [];
    let searchLists;
    
    for (let i = 0; i < array.length; i++) {

        searchLists = array[i].toString(field).toLowerCase();

        if (field === 'name') {
            searchLists = array[i].name.toLowerCase();
        }
         if (field === 'title') {
            searchLists = array[i].title.toLowerCase();
        }
        if (field === 'artist') {
            searchLists = array[i].artist.toLowerCase();
        }
        if (field === 'searchtitle') {
            searchLists = array[i].searchtitle.toLowerCase();
        }
        if (field === 'quote') {
            searchLists = array[i].quote.toLowerCase();
        }
        if (field === 'kindle_highlights') {
            searchLists = array[i].kindle_highlights.toLowerCase();
        }
        if (field === 'searchlyrics') {
            searchLists = array[i].searchlyrics.toLowerCase();
        }
        if (field === 'searchdescription' && array[i].searchdescription !== null) {
            searchLists = array[i].searchdescription.toLowerCase();
        }

        if (searchLists.includes(searchText))
        
            {searchArray.push(array[i]);}
    } 
    return searchArray;

};

