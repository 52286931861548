import React, { useEffect, useState } from "react"
import axios from 'axios';
import { dbUrlPeopleUnranked, dbUrlWTDlist, dbURListsRefswtd } from '../Constants';
import '../wisdom.css';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreAndLess from "react-read-more-less";
import SEO from "../../SEO";
import {useParams, Link} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import dayjs from "dayjs";

const PeopleUnrankedLists = () => {
    const [People, setPeople] = useState([])
    const [list, setList] = useState([])
    const [references, setReferences] = useState([])
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [error, setError] = useState(null)
    const [errorList, setErrorList] = useState(null)
    const [errorReferences, setErrorReferences] = useState(null)

    const params = useParams();

    //console.log(params);
    
    useEffect(() => {
        const fetchPeople = () => {
          axios.get(dbUrlPeopleUnranked + params.slug)
          .then(response => {
            setLoading2(false)
            setPeople(response.data)
          }).catch(error => {
            setError(error);
          });
        }

        const fetchList = () => {
            axios.get(dbUrlWTDlist + params.slug)
            .then(response => {
                setLoading(false)
                setList(response.data)
            }).catch(errorList => {
                setErrorList(errorList);
            });
        }

        const fetchReferences = () => {
            axios.get(dbURListsRefswtd + params.slug)
            .then(response => {
                setReferences(response.data)
            }).catch(errorReferences => {
                setErrorReferences(errorReferences);
            });
        }

        fetchList()
        fetchPeople()
        fetchReferences()
      }, [params.slug])
  
    if (error) return `Error people: ${error.message}`;
    if (errorList) return `Error List: ${errorList.message}`;
    if (errorReferences) return `Error References: ${errorReferences.message}`;

    const scrollToBottom = () => {
        scroll.scrollToBottom();
      };

    const scrollToTop = () => {
        scroll.scrollToTop(); 
    };


    if (loading || loading2) {
        return <div>
                <main className="my-4 py-4"><div className="container"> 
                <div className="row pt-5"><div className="col">
                <h4>Loading...</h4></div></div></div></main>;
            </div>
    }

    if (list.length < 1) {
        return <div>
                    <main className="my-1 py-1">
                        <div className="container">  
                            <div className="row pt-1">
                                <div className="col">
                                
                                    <h1>Oops! You seem to be lost.</h1>
                                    <p>Here are some helpful links:</p>

                                    <ul>
                                        <li><Link to='/'>Home</Link></li>
                                        <li><Link to='/lists'>All Lists</Link></li>
                                    </ul>
                                    
                                
                                    
                                </div>
                            </div>
                        </div>
                    </main>
            </div> 
    }

    const {name, description, pic_link, page} = list[0];

    let sortedReferences;

    sortedReferences = references.sort((a, b) => {
        return b.ref_number - a.ref_number;
    });

    const ListHeading = list.map(value => {
        
        return (
            <React.Fragment key={value.id}>
            
                <div className="row mb-3 gx-5">
                    <div>
                        <h1>{value.name}</h1>
                    </div>

                    <div className="col-sm-5 Description">

                        <div className="mt-2"><img src={value.pic_link} alt="alt" className="img-fluid" /> 
                        </div>
                        
                    </div>
                    
                    <div className="col-sm-7">

                        <div className="p-3 mt-2 border bg-light Description2">
                            {value.description} 
                        </div>
                        
                        
                        
                    </div>
                </div>

                <div className="row">
                    <div className="col pb-2" align="Right"> 
                        <button type="button" className="btn btn-primary btn-sm" onClick={scrollToBottom}>
                        <b>Scroll down</b>
                        </button>
                    </div>
                </div>
                   
            </React.Fragment>
            )
    })

    const PeopleItems = People.map(value => {

        let DescCharLimit

        if (value.people_desc) {
            DescCharLimit = value.people_desc;
        }
        else {
            DescCharLimit = 750
        }

        return (

            <React.Fragment key={value.id}>
                <div className="row pt-3 pb-3 border-bottom border-top border-info">

                    {/* kolonne 1 */}
                    <div className="col-sm-3">
                        <div className='PeopleName18 text-center'><b>{value.first_name} {value.last_name}</b></div>
                        <div className="mt-2 text-center"><img src={value.pic_link} alt="alt" className="img-fluid" /> 
                        </div>
                        <div className="mt-3 ms-2 p-2 Text13 bg-light rounded">
                            {value.birth_date &&
                            <div><b>Born: </b>{dayjs(value.birth_date).format('MMMM D, YYYY')}</div>
                            }
                            {value.birth_txt &&
                            <div><b>Born: </b>c. {value.birth_txt}</div>
                            }
                            <div>{value.born_place}</div>

                            {value.dead_date &&
                            <div className="mt-2"><b>Dead: </b>{dayjs(value.dead_date).format('MMMM D, YYYY')}</div>
                            }
                            {value.dead_txt &&
                            <div className="mt-2"><b>Dead: </b>c. {value.dead_txt}</div>
                            }
                            <div>{value.dead_place}</div>

                            {(value.resting_place||value.literature_resting_place) &&
                                <div className="mt-3"
                                    ><b>Resting place: </b>{value.resting_place||value.literature_resting_place}
                                </div>
                            }
                            
                            
                        </div>
                        
                    </div>

                    {/* kolonne 2 */}
                    <div className="col-sm-5 mt-2">
                        {value.main_comment &&
                            <div className="card bg-light mb-3 px-2 py-1">
                                <div className="quote13">{value.main_comment}</div> 
                                <div className="blockquote-footer mt-1 quote12"><i>{value.main_comment_source}</i></div>
                            </div>
                        }

                        <div className="Description2 mb-3">
                            <ReadMoreAndLess
                                charLimit={DescCharLimit}
                                readMoreText={" Read more ▼"}
                                readLessText={" Read less ▲"}
                                readMoreClassName="read-more-less--more"
                                readLessClassName="read-more-less--less"
                                >
                                {value.description}
                            </ReadMoreAndLess> 
                            <div className="mt-1"><em> -> <a href={value.wikipedia_link}>wikipedia</a></em></div>
                        </div>
                        {(value.main_interests||value.notable_ideas) &&
                        <div className="row">
                            <div className="col-sm-6 mt-2">
                                <div>
                                    <div className="PeopleHeader py-1 ps-2"><b>Main interests</b></div>
                                    <div className="pt-2 border-top Text13">
                                        <ReactMarkdown>
                                            {value.main_interests}               
                                        </ReactMarkdown>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 mt-2">
                                <div>
                                    <div className="PeopleHeader py-1 ps-2"><b>Notable ideas</b></div>
                                    <div className="pt-2 border-top Text13">
                                        <ReactMarkdown>
                                            {value.notable_ideas}               
                                        </ReactMarkdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {(value.literature_notable_work||value.notable_work) &&
                            <div>
                                <div className="PeopleHeader py-1 ps-2"><b>Notable works</b></div>
                                <div className="pt-2 border-top Text13">
                                    <ReactMarkdown>
                                        {value.notable_work||value.literature_notable_work}               
                                    </ReactMarkdown>
                            </div>
                        </div>
                        }
                        
                    </div>

                    {/* kolonne 3 */}
                    <div className="col-sm-3">
                        <div className="PeopleHeader2 py-1 ps-2 mb-2 rounded"><b>Selected quotes</b></div>
                        {
                            (() => {
                            if (value.quote1) {
                            return <div className="card bg-light mb-3 px-2 py-1">
                                        <div className="quote13">{value.quote1}</div> 
                                        <div className="blockquote-footer mt-1 quote12"><i>{value.source1}</i></div>
                                    </div>
                            }
                            return 
                            })()
                        }
                        {
                            (() => {
                            if (value.quote2) {
                            return <div className="card bg-light mb-3 px-2 py-1">
                                        <div className="quote13">{value.quote2}</div> 
                                        <div className="blockquote-footer mt-1 quote12"><i>{value.source2}</i></div>
                                    </div>
                            }
                            return 
                            })()
                        }
                        {
                            (() => {
                            if (value.quote3) {
                            return <div className="card bg-light mb-3 px-2 py-1">
                                        <div className="quote13">{value.quote3}</div> 
                                        <div className="blockquote-footer mt-1 quote12"><i>{value.source3}</i></div>
                                    </div>
                            }
                            return 
                            })()
                        }
                        {value.school &&
                            <div className="my-3 ms-2 p-2 Text18 bg-info rounded">
                                <div className="mt-1 ms-2 p-2 rounded">
                                    <div><b>School: </b>{value.school}</div>
                                </div>
                            </div>
                        }
                        <div className="mt-3 ms-2 p-2 Text18 bg-light rounded">

                            <div><b>Era: </b>{value.english_era||value.era}</div>

                            {value.sub_era &&
                                <div>{value.sub_era}</div>
                            }
                            {value.region &&
                                <div className="mt-2"><b>Region: </b>{value.region}</div>
                            }
                            
                            {(value.literature_movement||value.movement) &&
                                <div className="mt-2"><b>Movement: </b>{value.movement||value.literature_movement}</div>
                            }
                            
                        </div>
                    </div>

                </div>

                

            </React.Fragment>

        )

    })

    //REFERENCES
    const referenceItems = sortedReferences.map(value => {
        return (
            <React.Fragment key={value.id}>
                {
                        (() => {
                        if (value.book_title) {
                        return  <div className="row px-1 my-1">
                                    <div className="col">
                                        <div>{value.author_txt} ({value.published_year}). 
                                        &nbsp;<a href={value.more_info}>{value.book_title}</a>. {value.publisher}. (book) </div>
                                    </div>
                                </div>
                        } if (!value.date_txt && value.person) {
                        return <div className="row px-1 my-1">
                                <div className="col">
                                    <a href={value.url}>
                                        {value.title}
                                    </a> ({value.person})
                                </div>
                            </div>
                        } if (!value.date_txt && !value.person) {
                            return <div className="row px-1 my-1">
                                    <div className="col">
                                        <a href={value.url}>
                                            {value.title}
                                        </a>
                                    </div>
                                </div>
                        }
                        if (!value.person) {
                            return <div className="row px-1 my-1">
                                        <div className="col">
                                            <a href={value.url}>
                                                {value.title}
                                            </a> ({value.date_txt})
                                        </div>
                                    </div>
                        }
                        return <div className="row px-1 my-1">
                                    <div className="col">
                                        {value.person} ({value.date_txt}). <a href={value.url}>
                                            {value.title}
                                        </a>
                                    </div>
                                </div>
                        })()
                    }
            </React.Fragment>
        )
    })
    
    return (
        <main className="my-1 py-1">
            {People.length > 0 && (
                <div className="container">

                 <SEO
                    title={name}
                    description={description}
                    name={name}
                    picture_link={pic_link}
                    url={'https://www.wisdomthisday.com/'+page}
                    type='article' />


                    <div className="row">
                        <div className="col">
                            {ListHeading}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            {PeopleItems}
                        </div>
                    </div>

                    {(references.length > 0) &&
                        <div>
                                <div className="row mt-4">
                                    <div className="col">
                                            <h4>References</h4>
                                    </div>
                                </div>
                                <div className="row px-1">
                                    <div className="col">
                                        <b>
                                        <a href='https://en.wikipedia.org'>
                                            Wikipedia
                                        </a>
                                        </b>
                                    </div>
                                </div>
                            
                                <div className="row my-2">
                                    <div className="col">
                                            { referenceItems }
                                    </div>
                                </div>
                        </div>   
                    }

                    <p align="Right"><br />
                        <button type="button" className="btn btn-primary btn-sm" onClick={scrollToTop}>
                        <b>Scroll to top</b></button>
                    </p>


                </div>
            )}
        </main>
    )
}

export default PeopleUnrankedLists