export const dbURL = "https://api2204.musicthisday.com/";
export const dbURLlocal = "http://localhost:3010/";

export const dbUrlWTDlist = dbURL + 'wtdlist/';
export const dbUrlBooks = dbURL + 'books/';
export const dbUrlBookHighlightsHeading = dbURL + 'kindlehighlightsbook/';
export const dbUrlQuotes = dbURL + 'quotes/';
export const dbUrlAllQuotes = dbURL + 'allquotes';
export const dbUrlTravelPlanning = dbURL + 'travel-lists-planning/';
export const dbUrlPeopleUnranked = dbURL + 'peoplewtdunranked/';
export const dbUrlWandPCharacters = dbURL + 'wapcharacters';
export const dbUrlTVShows = dbURL + 'tv-ranked/';
export const dbUrlTVNetworksLists = dbURL + 'tv-networks-in-lists/';
export const dbUrlTravelLists = dbURL + 'travel-lists/';
export const dbUrlAttractionsLists = dbURL + 'travelattractions/';
export const dbUrlTubeOptions = dbURL + 'travelattrtotube/';
export const dbUrlWineGrapes = dbURL + 'winegrapes';
export const dbUrlRegionOptions = dbURL + 'winegrapestoregion';
export const dbUrlWineSubRegions = dbURL + 'winesubregions/';

export const dbURListsRefswtd = dbURL +'listrefswtd/';

export const customStylesSmall = {
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    borderColor: '#9e9e9e',
    minHeight: '30px',
    height: '25px',
    width: '200px',
    boxShadow: state.isFocused ? null : null,
  }),

   container: (provided, state) => ({
      ...provided,
      height: '35px',
      width: '210px',
      //padding: '0 6px'
    }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: '30px',
    //width: '150px',
    padding: '0 6px'
  }),

  input: (provided, state) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '30px',
    //width: '150px',
  }),
};

export const customStyles2 = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '30px',
      height: '25px',
      width: '265px',
      boxShadow: state.isFocused ? null : null,
    }),

     container: (provided, state) => ({
        ...provided,
        height: '35px',
        width: '250px',
        //padding: '0 6px'
      }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      //width: '150px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      //width: '150px',
    }),
  };