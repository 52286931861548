
import React from 'react';
//import { Link } from "react-router-dom";
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';

const Header = () => (

    <Navbar bg="light" expand="lg">
        <Container>
            <Navbar.Brand href="/">
                    <img
                    alt=""
                    src="/images/wisdom.png"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    />{' '}
                WisdomThisDay
             </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                <Nav.Link href="/lists">Lists</Nav.Link>
                
                <NavDropdown title="Books" id="basic-nav-dropdown">
                    <NavDropdown.Item href="/books/all-books">All Books</NavDropdown.Item>
                    <NavDropdown.Item href="/kindlehighlights">Book Summaries & Highlights</NavDropdown.Item>
                    <NavDropdown.Item href="/books/war-and-peace-principal-characters">War and Peace - characters</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Quotes" id="basic-nav-dropdown">
                    <NavDropdown.Item href="/allquotes">All Quotes</NavDropdown.Item>
                    <NavDropdown.Item href="/quotes/my-top-10-latin-phrases">Top 10 Latin Phrases</NavDropdown.Item>
                    {/* <NavDropdown.Divider />
                    <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item> */}
                </NavDropdown>
                
                <NavDropdown title="Travel" id="basic-nav-dropdown">
                <NavDropdown.Item href="/travel-planning/provence-travel">Provence Travel - Villages & vineyards by bike</NavDropdown.Item>
                <NavDropdown.Item href="/travel-destination/london">London - Things to do</NavDropdown.Item>
                <NavDropdown.Item href="/travel-destination/copenhagen">Copenhagen - Things to do</NavDropdown.Item>
                </NavDropdown>

                <NavDropdown title="TV" id="basic-nav-dropdown">
                    <NavDropdown.Item href="/tv/the-50-best-tv-shows-of-2023">The 50 best TV shows of 2023 (The Guardian)</NavDropdown.Item>
                    <NavDropdown.Item href="/tv/the-25-best-tv-series-of-2023">The 25 Best TV Series of 2023 (RogerEbert.com)</NavDropdown.Item>
                    <NavDropdown.Item href="/tv/the-best-tv-shows-of-2023-you-need-to-stream">The best TV shows of 2023 you need to stream (TimeOut)</NavDropdown.Item>
                    
                </NavDropdown>
                <NavDropdown title="Wine" id="basic-nav-dropdown">
                    <NavDropdown.Item href="/wine/wine-grapes">Wine Grapes</NavDropdown.Item> 
                    <NavDropdown.Item href="/wine-regions/provence-wine">Provence wine</NavDropdown.Item> 
                </NavDropdown>
            </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
    
)

export default Header;