import React, { useEffect, useState } from "react"
import axios from 'axios';
import { dbURL } from './Constants';
import './wisdom.css';
import SEO from "../SEO";
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from "react-scroll";
import {DebounceInput} from 'react-debounce-input';
import Select from 'react-select';
import { SelectProperty, SearchLists } from "./misc/utils"
import ReactMarkdown from 'react-markdown';

const Lists = () => {

    const [listsData, setlistsData] = useState([])
    const [listsInProgressData, setlistsInProgressData] = useState([])
    const [loadingLists, setLoadingLists] = useState(true)
    const [loadingInProgressLists, setLoadingInProgressLists] = useState(true)
    const [errorLists, setErrorLists] = useState(null)
    const [errorInProgress, setErrorInProgress] = useState(null)
    const [searchString, setSearchString] = useState('')
    const [optionRange, setOptionRange] = useState('All')
    const [optionCategory, setOptionCategory] = useState(null)

    
   //console.log(optionArtist);

    let filterArray, CategoryOptions

    useEffect(() => {
        const fetchListsData = () => {
          axios.get(dbURL + 'listswtd')
          .then(response => {
            setLoadingLists(false)
            setlistsData(response.data)
          }).catch(errorLists => {
            setErrorLists(errorLists);
          });
        }

        const fetchInProgressData = () => {
            axios.get(dbURL + 'unfinishedlistswtd')
            .then(response => {
                setLoadingInProgressLists(false)
                setlistsInProgressData(response.data)
            }).catch(errorInProgress => {
                setErrorInProgress(errorInProgress);
            });
        }

        fetchListsData()
        fetchInProgressData()
      }, [])
  
    if (errorLists) return `Error List: ${errorLists.message}`;
    if (errorInProgress) return `Error ListInProgress: ${errorInProgress.message}`;

    CategoryOptions = SelectProperty(listsData, 'category')

    const selectedOptionCategory = (selectedCategory) => {
        setOptionCategory(selectedCategory);
    } 

   

    const scrollToBottom = () => {
        scroll.scrollToBottom();
      };

    const scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    if (loadingLists || loadingInProgressLists) {
        return <div>
                <main className="my-4 py-4"><div className="container"> 
                <div className="row pt-5"><div className="col">
                <h4>Loading...</h4></div></div></div></main>;
            </div>
    }

    const handleSearchChange = (e) => {
        setSearchString(e.target.value);
      };
    
    const clearSearchField = (e) => {
        setSearchString('')
        setOptionRange('All')
        }

    if (searchString) {
        filterArray = SearchLists(listsData, 'name', searchString.toLowerCase());
        } 
    if (optionRange === 'Philosophy' && !searchString) {
        filterArray = listsData.filter(e => e.category === 'Philosophy');  
        } 
    if (optionRange === 'Quotes' && !searchString) {
        filterArray = listsData.filter(e => e.category === 'Quotes');  
        } 
    if (optionRange === 'Books' && !searchString) {
        filterArray = listsData.filter(e => e.category === 'Books');  
        } 
    if (optionRange === 'Travel' && !searchString) {
        filterArray = listsData.filter(e => e.category === 'Travel');  
        } 
    if (optionRange === 'TV' && !searchString) {
        filterArray = listsData.filter(e => e.category === 'TV');  
        } 
    if (optionRange === 'unfinished') {
        filterArray = listsInProgressData;
    } 

    if (optionRange === 'All' && !searchString) {
        filterArray = listsData;  
        } 

    if (optionCategory) {
        filterArray = listsData.filter(e => e.category === optionCategory.value);
        } 
    
    //console.table(filterArray);


    const ListItems = filterArray.map(value => {

        let page = '/' + value.page;

        return (
            <React.Fragment key={value.page}>

            <div className="row pt-3 pb-3 border-bottom border-top border-info">
                
                <div className="col-sm-3 pb-3 text-center">
                    
                    <div align="center">
                        {/* <img src={value.picture_link} alt="" height="175" width="175" /> */}
                        <img src={value.pic_link} alt="" className="img-fluid"></img>
                    </div>

                </div>

                <div className="col-sm-6 pb-3 border-right">
                    
                    <p className='Header'><b><Link to={page}>{value.name}</Link></b></p>
                       
                    <div className="factTextNoWrap rounded m-2 p-2">
                        <ReactMarkdown>
                            {value.description}                       
                        </ReactMarkdown>
                    </div>
                        

                </div>
              
                
                    <div className="col-sm-3 pb-4">

                        <div className="row">
                        
        
                            <div className="col pr-0 mr-2">
                                <div className="row">
                                    <div className="col-4 mr-2"><b>Category: </b></div>
                                    <div className="col-6 ml-2">{value.category}</div>
                                </div>
                                {value.source &&
                                <div className="row">
                                    <div className="col-4 mr-2"><b>Source: </b></div>
                                    <div className="col-6 ml-2">{value.source}</div>
                                </div>
                                }
                                {
                                    (() => {
                                    if (value.publish_date) {
                                    return <div>
                                         <div className="row">
                                            <div className="col-4"><b>Published: </b></div>
                                            <div className="col-6">{value.publish_date}</div>
                                        </div>
                                     </div>
                                    }
                                    return
                                    })()
                                }

                                   
                                    <br /><br />
                                    {
                                        (() => {
                                        if (value.in_progress) {
                                        return  <b><span className="p-1 mb-2 bg-info text-white">In progress</span></b>
                                        }
                                        return
                                        })()
                                    }

                            </div>

                        </div>   
                    
                    </div>
                
                    

            </div>

            </React.Fragment>          
        )

    })
   

    return (
        <main className="my-1 py-1">
            {listsData.length > 0 && (
                <div className="container">

                 <SEO
                    title={'WTD Lists'}
                    description={'All kinds of lists; quotes, philosophy, books, poetry, etc..'}
                    name={'WTD Lists'}
                    url={'https://www.wisdomthisday.com/lists'}
                    picture_link={''}
                    canonical={'https://www.wisdomthisday.com/lists'}
                    type='article' />
                
                <h1>Lists</h1>
                
                <div className="row">
                        <div className="col-sm-3 mb-3">
                            <img src='https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/13ffc30d-56e8-4d4c-3d2d-ca602dc93200/public' 
                            alt="plato" className="img-fluid rounded mx-auto d-block" />
                            <br />
                            <b>Count: {filterArray.length}</b>
                        </div>

                        <div className="col-sm-5">
                               
                                <p>
                                <strong>I love lists.</strong><br />
                                In this app I will publish some of my favorite lists, with filtering options & different view styles.<br />
                                <br />
                                Any suggestions? Drop me an email: egil@wisdomthisday.com
                                    </p>
                                <p><strong>click on list name to visit list.</strong></p>

                                
                        </div>
                        
                        <div className="col-sm-4 mb-4 border-start border-info">

                            {(!searchString)  &&
                                <div className="row">   
                                    <div className="col">
                                        <h5>Filters</h5>
                                    </div>
                                </div>
                            }

                            {(!searchString)  && 
                                <div className="row pb-2"> 
                                    <div className="col-6">
                                            <Select
                                                //styles={{control: customControlStyles}}
                                                value={optionCategory}
                                                onChange={selectedOptionCategory}
                                                options={CategoryOptions}
                                                placeholder={'select category..'}

                                            />
                                    </div>

                                    <div className="col-6"> 
                                        <button type="button" className="btn btn-primary" onClick={() => setOptionCategory(null)}> Reset filter</button>
                                    </div> 

                                </div>
                            }

                            {(!optionCategory)  &&  
                                <div className="row mt-2">   
                                        <div className="col">
                                        <h5>Search lists</h5>
                                    </div>
                                </div>
                            }
                            {(!optionCategory)  &&  
                            <div className="row mb-1">  
                                
                                <div className="col-6">
                                    <div className="search">    
                                        <DebounceInput
                                            //type="number"
                                            minLength={3}
                                            debounceTimeout={1000}
                                            value={searchString}
                                            onChange={handleSearchChange}
                                            placeholder="Search all lists" 
                                        />
                                    </div>
                                </div>
                                <div className="col-6 pl-0">
                                    <button type="button" className="btn btn-primary"  
                                    onClick={() => clearSearchField()}>Reset</button>
                                </div>

                            </div>

                               
                            
                            
                            }
                           

                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-sm-4" align="Left">
                            {optionRange === '' && 
                            <button type="button" className="btn btn-info btn-xs active focus mt-1" onClick={() => setOptionRange('All')}>All categories</button>
                            }
                            {optionRange !== '' && 
                            <button type="button" className="btn btn-info btn-sm mt-1" onClick={() => setOptionRange('All')}>All categories</button>
                            }
                            &nbsp;&nbsp;
                            <button type="button" className="btn btn-info btn-sm mt-1" onClick={() => setOptionRange('Philosophy')}>Philosophy</button>
                            &nbsp;&nbsp;
                            <button type="button" className="btn btn-info btn-sm mt-1" onClick={() => setOptionRange('Quotes')}>Quotes</button>
                            &nbsp;&nbsp;
                            <button type="button" className="btn btn-info btn-sm mt-1" onClick={() => setOptionRange('Books')}>Books</button>
                            &nbsp;&nbsp;
                        </div>
                        <div className="col-sm-6" align="Left">
                            <button type="button" className="btn btn-info btn-sm mt-1" onClick={() => setOptionRange('Travel')}>Travel</button>
                            &nbsp;&nbsp;
                            <button type="button" className="btn btn-info btn-sm mt-1" onClick={() => setOptionRange('TV')}>TV</button>
                            &nbsp;&nbsp;
                            {(window.location.hostname === "localhost") &&
                            <button type="button" className="btn btn-info btn-sm mt-1" onClick={() => setOptionRange('unfinished')}>Lists in progress</button>
                            }
                            
                        </div>


                        <div className="col-sm-2 pb-2" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm mt-1" onClick={scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>
                    </div>
                    
                    
                    <div className="row">
                        <div className="col-sm-12">      
                                 {ListItems} 
                        </div>
                    </div>
                    

                    <p align="Right"><br />
                        <button type="button" className="btn btn-primary btn-sm" onClick={scrollToTop}>
                        <b>Scroll to top</b></button>
                    </p>

                </div>
                
            )}
        </main>
    )


}

export default Lists